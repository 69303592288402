import { render, staticRenderFns } from "./ForYou.vue?vue&type=template&id=17df1578&scoped=true"
import script from "./ForYou.vue?vue&type=script&lang=js"
export * from "./ForYou.vue?vue&type=script&lang=js"
import style0 from "./ForYou.vue?vue&type=style&index=0&id=17df1578&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17df1578",
  null
  
)

export default component.exports