<template>
  <div class="for-you">
    <!-- featured slider -->
    <div class="segment">
      <div class="segment-content">
        <template v-if="!loading">
          <div
            class="heading-small margin-bottom-lg"
            v-text="$translations['for-you'].featured"
          />
          <FeaturedSlider v-if="featured" :items="featured" />
        </template>
        <template v-else>
          <Skeleton
            class="margin-bottom-lg"
            width="120px"
            height="21px"
            borderRadius="16px"
          />
          <div class="for-you-skeleton">
            <Skeleton width="100%" height="270px" borderRadius="16px" />
            <Skeleton width="80px" height="10px" borderRadius="16px" />
          </div>
        </template>
      </div>
    </div>

    <div class="for-you-filter">
      <FilterChipsCarrousel
        class="for-you-carrousel"
        :items="localContentTypes"
        :loading="loading"
        gradient-type="gray"
        button-color="purple"
        @value="handleSelect"
        @clean="handleReset"
      />
    </div>

    <template v-if="loading">
      <div
        class="page-segment margin-top"
        :key="key + '-'"
        v-for="(value, key) of 2"
      >
        <Skeleton
          class="margin-bottom"
          width="10%"
          height="21px"
          borderRadius="8px"
        />
        <div class="for-you-skeleton-card">
          <div v-for="(v, i) in 4" :key="i">
            <Skeleton
              class="margin-top-xs"
              width="240px"
              height="120px"
              borderRadius="16px"
            />
            <Skeleton
              class="margin-top-xs"
              width="240px"
              height="44px"
              borderRadius="16px"
            />
            <Skeleton
              class="margin-top-xs"
              width="140px"
              height="16px"
              borderRadius="16px"
            />
            <Skeleton
              class="margin-top-xs"
              width="180px"
              height="15px"
              borderRadius="16px"
            />
          </div>
        </div>
      </div>
    </template>
    <Carrousel
      :category-name="value.code"
      :title="value.text"
      :key="key"
      :custom-filter="selectedFilter"
      v-for="(value, key) of categories"
    />
  </div>
</template>

<script>
import FeaturedSlider from '@/views/for-you/components/v2/FeaturedSlider'
import Carrousel from './components/v2/Carrousel'
import { Skeleton, FilterChipsCarrousel, ForYou } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
import { ForYouEvent } from '@/types/events'

export default {
  name: 'ForYou',
  components: {
    FeaturedSlider,
    Carrousel,
    Skeleton,
    FilterChipsCarrousel
  },
  data () {
    return {
      selectedFilter: null,
      localContentTypes: []
    }
  },
  created () {
    setTimeout(() => {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    })

    if (!this.categories.length) {
      this.$store.dispatch('forYou/setup')
    }

    if (!this.featured.length && !this.loading) {
      this.$store.dispatch('forYou/list', {
        params: {
          featured: true
        }
      })
    }
  },
  mounted () {
    this.checkQueryParams()
  },
  methods: {
    checkQueryParams () {
      const slug = this.$route.query?.slug

      if (slug) {
        ForYou.entryBySlug(slug).then((item) => {
          const type = item['content_format'].code

          switch (type) {
            case 'AUDIO': {
              this.$globalEvent.$emit('meditation/open', {
                title: item.title,
                fileUrl: item.content
              })
              break
            }

            case 'VIDEO': {
              this.$globalEvent.$emit('youtube/open', {
                video: item
              })
              break
            }
          }
        })
      }
    },
    handleSelect (value) {
      const selectedValue =
        typeof value === 'string' ? JSON.parse(value) : value
      this.localContentTypes = this.contentTypes.filter(
        (o) => o.code === selectedValue.code
      )

      this.localContentTypes[0] = {
        ...this.localContentTypes[0],
        isActive: true
      }

      if (typeof value !== 'string') {
        selectedValue.type = 'content_type'
        this.$router.replace({
          query: {
            ...this.$route.query,
            filter: JSON.stringify(selectedValue)
          }
        })
      }

      this.selectedFilter = selectedValue

      ForYouEvent.contentTypeFilter({
        userId: this.$store.getters['auth/user'].id,
        selectedContentType: selectedValue.code
      })
    },
    handleReset () {
      this.localContentTypes = [...this.contentTypes]
      this.selectedFilter = null
      this.$router.replace({
        query: {
          ...this.$route.query,
          filter: null
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      categories: 'forYou/categories',
      contentTypes: 'forYou/contentTypes',
      featured: 'forYou/featured',
      loading: 'forYou/loading'
    })
  },
  watch: {
    contentTypes: {
      handler (value) {
        const filter = this.$route?.query?.filter
        if (filter) {
          this.handleSelect(filter)
        } else {
          this.localContentTypes = [...value]
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.segment
  padding-bottom: 0
.for-you-skeleton
  display: flex
  flex-direction: column
  gap: 20px
  align-items: center

.for-you-filter
  margin: auto
  max-width: 610px

.for-you-skeleton-card
  display: flex
  gap: 10px
</style>
