<template>
  <div class="slider">
    <div
      id="slider-container"
      class="slider-container"
      :class="{ 'slider-container-scroll': isMobile }"
    >
      <SwipperCarrousel
        :show-arrows="!isMobile && showArrows"
        :space-between="10"
        :show-bullets="true"
        @mouseover="!showArrows"
      >
        <template v-slot:slides>
          <div
            v-for="(item, index) in items"
            :key="index"
            class="display-card swiper-slide"
            :style="{
              background: `linear-gradient(180deg, rgba(24, 5, 47, 0) 27.08%, rgba(24, 5, 47, 0.75) 100%), url(${item.thumbnail})`
            }"
            @click="onClick(item)"
            @touchstart="onClick(item)"
          >
            <!-- icon -->
            <!-- TODO: icon heart -->
            <!-- <div class="icon-container">
            <div :class="item.icon" />
          </div> -->
            <!-- duration -->
            <div class="display-card-text small">
              <div class="display-card-text_duration">
                <span
                  class="display-card-text_icon"
                  :class="item.content_format.icon"
                />
                <span v-text="displayConfig(item)" />
              </div>
              <div
                class="display-card-text_title content-title text-white"
                v-text="item.title"
              ></div>
            </div>
          </div>
        </template>
      </SwipperCarrousel>
    </div>
  </div>
</template>

<script>
import SliderScrollMixin from '@/mixins/slider-scroll-mixin'
import MediaContentMixin from '@/mixins/media-content-mixin'
import ResponsiveMixin from '@/mixins/responsive.mixin'
import { ForYouEvent } from '@/types/events'
import SwipperCarrousel from '@/components/misc/SwipperCarrousel.vue'

export default {
  name: 'FeaturedSlider',
  mixins: [SliderScrollMixin, MediaContentMixin, ResponsiveMixin],
  components: {
    SwipperCarrousel
  },
  props: {
    items: Array
  },
  data () {
    return {
      elementId: 'slider-container',
      showArrows: false
    }
  },
  methods: {
    onClick (item) {
      this.elementClickTime = setTimeout(() => {
        const body = {
          page: this.$route.name,
          origin: 'Para ti',
          component: 'FeaturedSlider',
          ...item
        }

        ForYouEvent.contentCardOpen(body)
        this.showDetail(item)
      }, 300)
    }
  },
  computed: {
    displayConfig () {
      return (value) => {
        return `${value.content_type.text} • ${this.durationFormat(
          value.duration
        )}`
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.swiper-slide
  height: 270px

.slider
  display: flex
  flex-direction: column
  gap: 20px
  align-items: center
  position: relative

  &-container
    display: flex
    max-width: 562px
    height: 320px
    width: 100%
    gap: 8px
    border-radius: 24px
    overflow-x: hidden
    scroll-snap-type: x mandatory
    cursor: pointer

.display-card
  display: flex
  flex-direction: column
  background-position: center !important
  background-size: cover !important
  justify-content: flex-end
  width: 100%
  min-width: 100%
  scroll-snap-align: start
  border-radius: 24px
  .icon-container
    display: flex
    align-items: center
    justify-content: center
    align-self: flex-end
    color: var(--white)
    width: 30px
    height: 30px
    padding: 5px
    margin: 20px 20px 0 0
    background: rgba(0, 0, 0, 0.4)
    border-radius: 8px
  &-text
    display: flex
    flex-direction: column
    margin: 0 0 20px 20px
    gap: 5px
    align-self: flex-start
    color: var(--white)
    &_duration
      display: flex
      align-items: center
      gap: 5px
    &_icon
        font-size: 20px
</style>
